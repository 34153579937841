// Override default variables before the import
$body-bg: #000;
$enable-gradients: true;

$colors: (
    "nav-color": rgba(13, 14, 13, 1),
    "header-color": rgba(40, 44, 52, 0.459),
    "lime-green-text": rgb(24, 71, 24),
    "navbar-emoji-bg": rgba(52, 58, 64, 1),
);

// Import Bootstrap and its default variables
// Custom.scss
// Option B: Include parts of Bootstrap

// Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Share+Tech&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oxygen&display=swap');

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .my-card:not(:first-child) {
        margin-left: -100px;
    }
    .my-card:focus-within~.my-card, .my-card:hover~.my-card {
        transform: translateX(100px);
    }
    .my-card {
        min-width: 300px;
        height: 65vh;
        padding: 1.2rem;
        min-height: 520px;
    }
    .card-list {
        padding: 3.5rem;
        height: 75vh;
        width: 100vw;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .my-card:not(:first-child) {
        margin-left: -100px;
    }
    .my-card:focus-within~.my-card, .my-card:hover~.my-card {
        transform: translateX(100px);
    }
    .my-card {
        min-width: 400px;
        height: 50vh;
        padding: 1.2rem;
        min-height: 520px;
    }
    .card-list {
        height: 60vh;
        width: 100vw;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .my-card:not(:first-child) {
        margin-left: -150px;
    }
    .my-card:focus-within~.my-card, .my-card:hover~.my-card {
        transform: translateX(150px);
    }
    .my-card {
        min-width: 450px;
        height: 50vh;
        padding: 1.2rem;
        min-height: 520px;
    }
    .card-list {
        height: 60vh;
        width: 100vw;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .my-card:not(:first-child) {
        margin-left: -200px;
    }
    .my-card:focus-within~.my-card, .my-card:hover~.my-card {
        transform: translateX(200px);
    }
    .my-card {
        min-width: 500px;
        height: 53vh;
        padding: 1.2rem;
        min-height: 580px;
    }
    .card-list {
        height: 63vh;
        width: 100vw;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .my-card:not(:first-child) {
        margin-left: -250px;
    }
    .my-card:focus-within~.my-card, .my-card:hover~.my-card {
        transform: translateX(250px);
    }
    .my-card {
        min-width: 550px;
        height: 53vh;
        padding: 1.2rem;
        min-height: 580px;
    }
    .card-list {
        height: 63vh;
        width: 100vw;
    }
}

#root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
    
#content {
    display: flex;
    justify-content: center;
}
    
#header {
    height: 5rem;
    background-color: white;
    color: var(--text-primary);
}

#header ul, li {
    margin: 0;
    padding: 0;
    font-family: "Share Tech", sans-serif;
}

#header ul {
    padding: 25px;
    position: absolute;
    top: 70px;
    width: 230px;
}

#header li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

#body {
    width: 100vw;
    margin: 0px;
    padding: 0px;
}

#frontpage {
    // position: relative;
    justify-content: left;
    text-align: left;
    height: 95vh;
    width: 100vw;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding:3px;
}

.frontpage-content {
    display: grid;
    place-content: center;
}

#frontpage p {
    text-align: left;
    position: relative;
    top: 26vh;
    left: 0.2rem;
    margin: 0px;
    font-size: clamp(15px, 1.7vw, 30px);
    max-width: 50rem;
}

#frontpage h1 {
    padding:0;
    position: relative;
    top: 25vh;
    color: #66c4e8;
    font-size: clamp(40px, 8vw, 80px);
    margin: 0px;
}

#frontpage a {
    margin-top: 30px;
    display: inline-block;
    cursor: pointer;
    line-height: 1;
    border: 2px solid var(--text-primary);
    padding-top: 1.25rem;
    padding-right: 1.75rem;
    padding-bottom: 1.25rem;
    padding-left: 1.75rem;
    position: relative;
    left: 0.2rem;
    top: 25vh;
    color: var(--text-secondary);
    border-radius: 5px;
    font-size: clamp(16px, 2vw, 30px);
    transition: opacity 0.4s ease;
    background: var(--text-primary-gradient);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    width: fit-content;
}

#frontpage a:hover{
    opacity: 0.7
}

#frontpage h3 {
}

#aboutme {
    justify-content: left;
    text-align: left;
    vertical-align: center;
    width: 100vw;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    min-height: 50vh;
}
#tech-about-me {
    margin-top: 40px;
}
h1 {
    color: var(--text-primary);
    padding-top: 10px;
    padding-bottom: 0px;
    font-size: clamp(30px, 6vw, 60px);
    background: var(--text-primary-gradient);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}


h2 {
    font-size: clamp(20px, 3vw, 45px);
}

hr {
    border: 1px solid rgb(190, 232, 238);
    margin-bottom: 20px;
}

#aboutme h1 {
    margin-bottom: 2rem;
}

#aboutme p {
    color:black;
    font-size: large;
    text-align: left;
    font-size: clamp(15px, 1.4vw, 23px);
}
#aboutme img {
    margin-bottom: 20px;
    border: 4px solid var(--text-primary);
}
#aboutme h5 {
    color: #66c4e8;
    margin: 0px;
    padding-top: 10px;
    font-size: clamp(20px, 2vw, 40px);
    margin-bottom: 5px;
    background: var(--text-primary-gradient);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}
#aboutme ul {
    position: relative;
    top: 0px;
    padding-left: 0;
    list-style-type: none;
}
#aboutme ul li {
    margin: 0px;
    text-align: left;
    vertical-align: center;
    font-size: clamp(15px, 1.5vw, 23px);
}
#aboutme ul li:before {
    position: relative;
    top: 3px;
    color: var(--text-primary);
    font-family: "Material Icons";
    font-size: clamp(15px, 1.5vw, 23px);
    content: "done";
    background: var(--text-primary-gradient);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

#cardlist {
    min-height: 95vh;
    justify-content: left;
    text-align: left;
    width: 100vw;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    vertical-align: center;
    padding: 3px;
}
#cardlist ul, li {
    font-family: 'Open Sans', sans-serif;
    font-family: 'Oxygen', sans-serif;
}

#cardlist a {
    text-decoration: none;
}

.card-list {
    display: flex;
    padding-top: 5rem;
    padding: 3rem;
    width: 100vw;
    padding-bottom: 7rem;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: center;
    max-width: 1200px;
    min-height: 685px;
}

.card-list::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}
.card-list::-webkit-scrollbar-thumb {
    background: lightskyblue;
    border-radius: 20px;
}

.card-list::-webkit-scrollbar-track {
    background: lightgray;
}


.my-card {
    font-family: 'Open Sans', sans-serif;
    font-family: 'Oxygen', sans-serif;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0rem 15px 2rem 3px #000;
    display: flex;
    flex-direction: column;
    transition: .2s;
    margin-top: 2rem;
    scroll-snap-align: start;
    clear: both;
    position: relative;
}

.my-card:hover {
    transform: translateY(-1rem);
}

.my-card-header {
    flex-basis: 15%;
    margin-bottom: 10px;
    background-color: #00baed2c;
    border-radius: 20px;
}

.my-card-header p:first-child {
    margin-top: 1rem;
    margin-left: 0.7rem;
    font-size: clamp(15px, 0.5vw, 25px);
    margin-bottom: -8px;
    color: var(--text-primary);
}

.my-card-header h2 {
    margin-left: 0.6rem;
    font-size: clamp(25px, 2vw, 35px);
    text-decoration: none;
    color: inherit;
    border: 0;
    display: inline-block;
    cursor: pointer;
}

.my-card-header p:nth-child(3) {
    margin-top: 0rem;
    margin-left: 0.6rem;
    margin-bottom: 1rem;
    font-size: clamp(16px, 1.2vw, 25px);
    text-decoration: none;
    color: gray;
    border: 0;
    display: block;
    cursor: pointer;
}


.my-card:hover h2 {
    background: var(--text-primary-gradient);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.my-card ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: auto;
}


.my-card ul, li {
    margin: 0px;
    text-align: left;
    vertical-align: center;
    font-size: clamp(10px, 1vw, 13px);
}

.my-card li:before {
    position: relative;
    top: 5px;
    color: var(--text-primary);
    font-family: "Material Icons";
    font-size: clamp(15px, 1.5vw, 23px);
    content: "arrow_right_alt";
    background: var(--text-primary-gradient);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.my-card-author {
    margin: 3rem 0 0;
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: center;
    position: relative;
}

.author-avatar {
    grid-area: auto;
    align-self: start;
    position: relative;
    box-sizing: border-box;
}

.author-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    filter: grayscale(100%);
    display: block;
    overflow: hidden;
    margin: 16px 10px;
}

.author-name {
    color: var(--text-primary);
    grid-area: auto;
    box-sizing: border-box;
}

.author-name-prefix {
    font-style: normal;
    font-weight: 700;
    //#00fff2,#2e65e5);
    color: var(--text-primary);
}

.half-circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 48px;
    fill: none;
    stroke: var(--text-primary);
    stroke-width: 8;
    stroke-linecap: round;
    pointer-events: none;
}

.tags {
    margin: 1rem 0 2rem;
    padding: .5rem 0 1rem;
    line-height: 2;
    margin-bottom: 0;
    margin-top: auto;
}

.tags a {
    font-style: normal;
    font-weight: 700;
    font-size: .5rem;
    color: var(--text-primary-gradient);
    text-transform: uppercase;
    font-size: .66rem;
    border: 3px solid var(--text-primary);
    border-radius: 2rem;
    padding: .2rem .85rem .25rem;
    position: relative;
}

.tags a:hover {
    background: var(--text-primary-gradient);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-box-decoration-break: clone;
    background-clip: text;
    border-color: white;
}

.background {
    position: absolute;
    border-radius: 15px;
    border-top-left-radius: 0px;
    left: 0;
    bottom: 0;
    width: 15rem;
    background-color: white;
}

.navbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 4px 4px 20px 4px rgba(15, 15, 15, 0.45);
    transition: top 0.6s, box-shadow 0.6s;
    z-index: 5;
}

.navbar-hidden {
    top: -5rem;
    box-shadow: 0px 0px 0px 0px rgba(15, 15, 15, 0.45);
}

.light-gray-desc {
    color: #ada9a9
}

.link-button {
    margin-top: auto;
    display: grid;
    place-content: center;
}

.link-button a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease;
    font-size: clamp(12px, 2vw, 26px);
}

.link-button a:hover {
    color: var(--text-primary)
}

:root {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Oxygen', sans-serif;
    --text-primary: #56CCF2;
    --text-secondary: #2F80ED;
    --text-primary-gradient: linear-gradient(90deg,#56CCF2,#2F80ED);
}

html, body {
    padding: 0;
    margin: 0;
}

body {
    margin-top: 5rem;
    width: 100vw;
    height: auto;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
}

body::-webkit-scrollbar {
    width: 0.5rem;
}
body::-webkit-scrollbar-track {
    background: white;
    border-radius: 20px;
}
body::-webkit-scrollbar-thumb {
    background: lightskyblue;
    border-radius: 20px;
}

nav {
    position: absolute;
    width: 15rem;
    top: 0px;
    left: 0;
}

button {
    outline: none;
    border: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 15px;
    left: 14px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
}

.icon-placeholder {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    flex: 40px 0;
    margin-right: 20px;
    background: white;
}

.text-placeholder {
    border-radius: 5px;
    width: 210px;
    height: 20px;
    flex: 1;
    justify-content: center;
    vertical-align: center;
    text-align: center;
    background: white;
}

.refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

span.emoji {
    font-size: 30px;
    vertical-align: middle;
    line-height: 2.35;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
}
a.nav-link {
    padding: 4px;
}

li p {
    color: black;
    padding: 0px 0px 10px 0px;
    margin: 0;
    position: relative;
    bottom: 4px;
    font-size: 15px;
}

.between-emoji {
    margin-bottom: 5px;
}